import {
  BiChart,
  BiGift,
  BiHourglass,
  BiPackage,
  BiSave,
  BiShoppingBag,
  BiSortAZ,
  BiStore,
  BiUser,
} from "react-icons/bi";
import { UserRoles } from "./global";

export interface MenuInterface {
  icon: React.FC;
  title: string;
  link: string;
  pathname: string;
  roles?: UserRoles[];
  subMenus?: MenuInterface[];
}
export const MENUS: MenuInterface[] = [
  {
    icon: BiChart,
    title: "Dashboard",
    link: "/dashboard",
    pathname: "dashboard",
    roles: [UserRoles.ADMIN],
  },
  {
    icon: BiHourglass,
    title: "Tombolas",
    link: "/",
    pathname: "make",
    subMenus: [
      {
        icon: BiHourglass,
        title: "Tombola périodique",
        link: "/make-hebdo-tombola",
        pathname: "make-hebdo-tombola",
        roles: [UserRoles.ADMIN],
      },
      {
        icon: BiHourglass,
        title: "Grande Tombola",
        link: "/make-big-tombola",
        pathname: "make-big-tombola",
        roles: [UserRoles.ADMIN],
      },
    ],
    roles: [UserRoles.ADMIN],
  },
  
  {
    icon: BiUser,
    title: "Clients",
    link: "/client",
    pathname: "client",
    roles: [UserRoles.ADMIN],
  },
  {
    icon: BiSortAZ,
    title: "Codes",
    link: "/code",
    pathname: "code",
    roles: [UserRoles.ADMIN, UserRoles.USER],
  },
  {
    icon: BiShoppingBag,
    title: "Lots",
    link: "/lot",
    pathname: "lot",
    roles: [UserRoles.ADMIN],
  },
  {
    icon: BiPackage,
    title: "Tirages éffectués",
    link: "/tombolas",
    pathname: "tombolas",
    roles: [UserRoles.ADMIN],
  },
  {
    icon: BiGift,
    title: "Gagnants",
    link: "/winner",
    pathname: "winner",
    roles: [UserRoles.ADMIN],
  },
  {
    icon: BiStore,
    title: "Points de vente",
    link: "/sales-point",
    pathname: "sales-point",
    roles: [UserRoles.ADMIN],
  },
  {
    icon: BiUser,
    title: "Utilisateurs",
    link: "users",
    pathname: "users",
    roles: [UserRoles.ADMIN],
  },

  {
    icon: BiSave,
    title: "Enregistrement client",
    link: "/client-save",
    pathname: "client-save",
    //roles: [UserRoles.ADMIN],
  },
];
