import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Card from "../../components/card/Card";
import { Form } from "../../components/form/Form";
import { SubmitButton } from "../../components/button/CustomButton";
import { InputDate, InputNumber } from "../../components/form/Input";
import { HTTPStatusResponse } from "../../config/global";
import { APP_API_URL } from "../../config/api";
import { useMutate } from "../../hooks/useMutations";
import "../../assets/styles/tombola.css";
import { ColumnInterface } from "../../components/table/typings";
import moment from "moment";
import Table from "../../components/table/Table";
import { useEffect, useState } from "react";
import { Select2 } from "../../components/form/Select";
import { useQueries } from "../../hooks/useQueries";
import Confetti from "react-confetti";
import ToggleButton from "../../components/button/ToggleButton";

export default function HebdoTombolaPage() {
  /**
   * Hooks
   */
  const [results, setResults] = useState([]);
  const [animateBall, setAnimateBall] = useState(false);
  const [isConfettiVisible, setConfettiVisible] = useState(false);
  const [isSend, setIsSend] = useState(false);

  /**
   * Form
   */
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const watchSalesPoint = watch("salesPointId", null);

  /**
   * Mutation
   */
  const { mutateAsync, isLoading } = useMutate(
    APP_API_URL + `tombola-period-store`,
    "post"
  );
  const { data: lots, refetch: refetchLots } = useQueries(
    APP_API_URL + `sales-point-lots/${watchSalesPoint?.id}`,
    ["lots", watchSalesPoint],
    {
      enabled: false,
    }
  );

  const { data: salesPoints } = useQueries(
    APP_API_URL + `sales-points?paginate=0`,
    ["salesPoints"]
  );

  /**
   * Function
   */
  const handleSubmitForm = (values: any) => {
    setResults([]);
    setAnimateBall(true);
    mutateAsync({
      ...values,
      numberWinner: values.numberWinner,
      lot: values.lot.name,
      salesPointId: values.salesPointId.id,
      //isLast: isLast ? 1 : 0,
      isSend: isSend ? 1 : 0,
    }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        toast.success("Tirage éffectué !");
        setConfettiVisible(true);
        setTimeout(() => {
          setConfettiVisible(false);
        }, 10000);
        setResults(response.data.data ?? []);
      } else {
        toast.error(response?.data.message);
      }
      setAnimateBall(false);
    });
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Code gagnant",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "Nom du client",
      key: "client",
      dataIndex: "client",
      render: (element: any) => <>{element.client.toUpperCase()}</>,
    },
    {
      title: "Numéro de téléphone",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Date de validation du ticket",
      key: "win_date",
      render: (element: any) => (
        <>{moment(element.win_date).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
  ];

  useEffect(() => {
    if (watchSalesPoint) {
      refetchLots();
    }
  }, [watchSalesPoint]);

  /**
   * UI
   */

  return (
    <>
      <div className="grid sm:grid-cols-2 gap-4">
        <Card title={"Tombola"}>
          <div className="mx-auto px-4 border border-gray-200 rounded-full w-[400px] h-[400px] border-b-[100px] border-t-[#fff] border-t-[25px] relative flex items-center">
            <div
              className={`rounded-full h-[400px] p-10 relative w-full ${
                animateBall ? "animate" : ""
              }`}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value: number) => (
                <span key={value} className="loader-tombola">
                  <span>{value}</span>
                </span>
              ))}
            </div>
          </div>
        </Card>
        <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)}>
          <Card
            title="Lancer la tombola hebdomadaire"
            extras={[
              <SubmitButton
                value="Démarrer"
                loading={isLoading}
                key="submit"
              />,
            ]}
          >
            <div className={"mb-5 grid grid-cols-2 gap-4"}>
              <div>
                <Select2
                  placeholder="Selectionner le point de vente"
                  control={control}
                  name={"salesPointId"}
                  error={errors?.salesPointId}
                  register={register}
                  textKeyItem={"name"}
                  valueKeyItem={"id"}
                  items={salesPoints?.data.data}
                  label="Point de vente"
                />
              </div>
              <div>
                <Select2
                  placeholder="Selectionner le lot"
                  control={control}
                  name={"lot"}
                  error={errors?.lot}
                  register={register}
                  textKeyItem={"name"}
                  valueKeyItem={"id"}
                  items={lots?.data.data}
                  label="Lot"
                />
              </div>
              <div>
                <InputDate
                  label={"Date de début"}
                  register={register}
                  error={errors?.startDate}
                  name={"startDate"}
                  defaultValue={moment()
                    .subtract(7, "day")
                    .format("YYYY-MM-DD")}
                />
              </div>
              <div>
                <InputDate
                  label={"Date de fin"}
                  register={register}
                  error={errors?.endDate}
                  name={"endDate"}
                  defaultValue={moment()
                    .subtract(1, "day")
                    .format("YYYY-MM-DD")}
                />
              </div>
              <div className={"mb-10 w-32"}>
                <InputNumber
                  placeholder={"Entrer le nombre de gagnants"}
                  label={"Nombre de gagnants"}
                  register={register}
                  error={errors?.numberWinner}
                  name={"numberWinner"}
                  defaultValue={1}
                />
              </div>
              <div className="flex gap-2 items-center">
                <span>Envoi automatique du sms</span>
                <ToggleButton
                  name={"isSend"}
                  enabled={isSend}
                  disabled={isLoading}
                  onClick={() => setIsSend(!isSend)}
                />
              </div>
              <div></div>
            </div>
            <Table
              tableClassName="text-left"
              columns={columns}
              loading={false}
              datas={results}
            />
          </Card>
        </Form>
      </div>

      {isConfettiVisible && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={1000}
          recycle={true}
          tweenDuration={10000}
          style={{ zIndex: 9999 }}
        />
      )}
    </>
  );
}
