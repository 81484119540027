import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { InputText, InputPhone } from "../components/form/Input";
import { HTTPStatusResponse, UserRoles } from "../config/global";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutate } from "../hooks/useMutations";
import Card from "../components/card/Card";
import { Form } from "../components/form/Form";
import SimpleButton from "../components/button/SimpleButton";
import { Select2 } from "../components/form/Select";
import { useState } from "react";
import { SubmitButton } from "../components/button/CustomButton";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import { Wizard, useWizard } from "react-use-wizard";
import LoaderBarWrapper from "../components/loader/LoaderBarWrapper";
import { BiCheckCircle } from "react-icons/bi";
import moment from "moment";
import CheckRole from "../utils/CheckRole";
import { FaInfoCircle } from "react-icons/fa";

export default function WizardPage() {
  /**
   * Hooks
   * */
  const [phoneNumber, setPhoneNumber] = useState("");
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const isAdmin = CheckRole([UserRoles.ADMIN]);
  const openHour = "07:30";
  const closeHour = "22:00";
  const currentHour = moment().format("HH:mm");
  //const openApp = (currentHour >= openHour && currentHour < closeHour) || isAdmin;
  const openApp = true;

  const queryKey = ["client"];

  const watchTown = watch("town", null);
  /**
   * Query
   */

  /**
   * Mutation
   */

  /**
   * Function
   */

  const { data: salesPoints } = useQueries(
    APP_API_URL +
      `sales-points?paginate=0${watchTown ? `&town=${watchTown.name}` : ""}`,
    ["salesPoints", watchTown]
  );
  const regex = /^(\+?237)?6((8|7|9|2)\d{7}|5([0-4]|[5-9])\d{6})$/;
  const Step1 = () => {
    const { goToStep } = useWizard();
    const { mutateAsync } = useMutate(APP_API_URL + `client-find`, "post");
    const [isLoading, setIsloading] = useState(false);
    const handleSubmitFormStep1 = (values: any) => {
      if (!regex.test(values.phone)) {
        toast.error("Numéro de téléphone invalide");
      } else {
        setIsloading(true);
        setPhoneNumber(values.phone);
        mutateAsync({
          ...values,
          phone: values.phone,
        })
          .then((response: any) => {
            if (response && response.data.status === HTTPStatusResponse.OK) {
              if (response.data.data) {
                reset();
                goToStep(2);
              } else {
                reset();
                goToStep(1);
              }
            } else {
              toast.error(response?.data.message);
            }
          })
          .finally(() => setIsloading(false));
      }
    };
    return (
      <>
        <div className="flex justify-center items-center">
          <div className="md:w-1/3 w-full">
            <LoaderBarWrapper loading={isLoading}>
              <Form
                loading={isLoading}
                onSubmit={handleSubmit(handleSubmitFormStep1)}
              >
                <div className="mb-4 gap-10">
                  <Card title="Enregistrement client">
                    <div className={"mb-5 gap-4"}>
                      <InputPhone
                        label="Entrez votre numéro de téléphone"
                        register={register}
                        error={errors?.phone}
                        name="phone"
                      />
                    </div>
                    <div className={"flex justify-end"}>
                      <SubmitButton
                        value="Suivant"
                        loading={isLoading}
                        key="submit"
                      />
                    </div>
                  </Card>
                </div>
              </Form>
            </LoaderBarWrapper>
          </div>
        </div>
      </>
    );
  };
  const Step2 = () => {
    const { nextStep, previousStep, goToStep } = useWizard();
    const { mutateAsync: storeData } = useMutateWithInvalidateQueries(
      APP_API_URL + `client-store`,
      "post",
      queryKey
    );
    const [isLoading, setIsloading] = useState(false);
    const handleSubmitFormStep2 = async (values: any) => {
      setIsloading(true);
      storeData({
        ...values,
        name: values.name,
        sex: values.sex.name,
        town: values.town.name,
        salesPointId: values.salesPoints.id,
        phone: phoneNumber,
      })
        .then((response: any) => {
          if (response && response.data.status === HTTPStatusResponse.OK) {
            reset();
            toast.success("Étapes suivante!");
            goToStep(2);
          } else {
            toast.error(response?.data.message);
          }
        })
        .finally(() => setIsloading(false));
    };
    return (
      <>
        <div className="flex justify-center items-center">
          <div className="md:w-1/3 w-full">
            <LoaderBarWrapper loading={isLoading}>
              <Form
                loading={isLoading}
                onSubmit={handleSubmit(handleSubmitFormStep2)}
              >
                <Card title={"Enregistrement client"}>
                  <div className="grid gap-4">
                    <div>
                      <Select2
                        placeholder="Selectionner la ville"
                        control={control}
                        name={"town"}
                        error={errors?.town}
                        register={register}
                        textKeyItem={"name"}
                        valueKeyItem={"id"}
                        items={[
                          { id: 1, name: "DOUALA" },
                          { id: 2, name: "YAOUNDÉ" },
                        ]}
                        label="Ville"
                        required={false}
                      />
                    </div>
                    <div>
                      <Select2
                        control={control}
                        placeholder={"Selection des points de vente"}
                        label={"Points de vente"}
                        register={register}
                        error={errors?.salesPoints}
                        name={"salesPoints"}
                        textKeyItem={"name"}
                        valueKeyItem={"id"}
                        items={salesPoints?.data?.data}
                        required={false}
                      />
                    </div>
                    <div>
                      <InputText
                        label={"Entrez votre nom"}
                        register={register}
                        error={errors?.name}
                        name={"name"}
                      />
                    </div>
                    <div className="mb-5 gap-4">
                      <Select2
                        placeholder="Selectionner votre sexe"
                        control={control}
                        name={"sex"}
                        error={errors?.sex}
                        register={register}
                        textKeyItem={"name"}
                        valueKeyItem={"id"}
                        items={[
                          { id: 1, name: "HOMME" },
                          { id: 2, name: "FEMME" },
                        ]}
                        label="Sexe"
                        required={false}
                      />
                    </div>
                    <div className={"flex gap-4 justify-end"}>
                      <SimpleButton
                        key={"export"}
                        onClick={previousStep}
                        className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
                      >
                        <span>Précédent</span>
                      </SimpleButton>
                      <SubmitButton
                        value="Suivant"
                        loading={isLoading}
                        key="submit"
                      />
                      ,
                    </div>
                  </div>
                </Card>
              </Form>
            </LoaderBarWrapper>
          </div>
        </div>
      </>
    );
  };
  const Step3 = () => {
    const { goToStep, previousStep } = useWizard();
    const { mutateAsync: storeFinalStepData } = useMutateWithInvalidateQueries(
      APP_API_URL + `verify`,
      "post",
      queryKey
    );
    const [isLoading, setIsloading] = useState(false);
    const handleSubmitFormStep3 = (values: any) => {
      setIsloading(true);
      storeFinalStepData({
        ...values,
        phone: phoneNumber,
      })
        .then((response: any) => {
          if (response && response.data.status === HTTPStatusResponse.OK) {
            reset();
            toast.success("Super!!!!");
            goToStep(3);
          } else {
            toast.error(response?.data.message);
          }
        })
        .finally(() => setIsloading(false));
    };
    return (
      <>
        <div className="flex justify-center items-center">
          <div className="md:w-1/3 w-full">
            <LoaderBarWrapper loading={isLoading}>
              <Form
                loading={isLoading}
                onSubmit={handleSubmit(handleSubmitFormStep3)}
              >
                <div className={"mb-5"}>
                  <Card title={"Enregistrement client"}>
                    <div className="mb-5 grid gap-4">
                      <div>
                        <InputText
                          label={"Entrez le numéro de votre transaction"}
                          register={register}
                          error={errors?.transaction_number}
                          name={"transaction_number"}
                        />
                      </div>
                      <div>
                        <InputText
                          label={"Entrez votre code"}
                          register={register}
                          error={errors?.num_code}
                          name={"num_code"}
                        />
                      </div>
                    </div>
                    <div className={"flex  gap-4 justify-end"}>
                      <SimpleButton
                        key={"export"}
                        onClick={previousStep}
                        className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
                      >
                        <span>Précédent</span>
                      </SimpleButton>

                      <SubmitButton
                        value="Envoyer"
                        loading={isLoading}
                        key="submit"
                      />
                    </div>
                  </Card>
                </div>
              </Form>
            </LoaderBarWrapper>
          </div>
        </div>
      </>
    );
  };
  const Step4 = () => {
    const { goToStep } = useWizard();
    return (
      <>
        <div className="flex justify-center items-center">
          <div className="md:w-1/3 w-full py-2">
            <Card title={"Enregistrement client"}>
              <div className="flex justify-center items-center mb-5 gap-4">
                <BiCheckCircle className="text-4xl text-green-500" />
              </div>
              <div className="mb-5 gap-4">
                <p className="text-xl">
                  Felicitations ! Ce ticket est inscrit au prochain tirage au
                  sort de la tombola 7e ANNIVERSAIRE CARREFOUR. Les resultats
                  seront notifiés par SMS.
                </p>
              </div>
              <div className={"flex justify-end"}>
                <SimpleButton
                  key={"Retour"}
                  onClick={() => goToStep(0)}
                  className="bg-soft-primary"
                  value={"Retour"}
                >
                  <span>Retour</span>
                </SimpleButton>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  };

  /**
   * Columns Table
   */

  /**
   * UI
   */
  return (
    <div className="relative">
      {!openApp ? (
        <div className="fixed inset-0 bg-white bg-opacity-95 pointer-events-none z-10 flex items-center justify-center">
          <p className="text-red-500 text-4xl uppercase text-center">
            <FaInfoCircle className="mx-auto" size={45} />
            <span>
              Les heures d'ouverture sont comprises entre {openHour} et{" "}
              {closeHour}
            </span>
          </p>
        </div>
      ) : (
        <Wizard>
          <Step1 />
          <Step2 />
          <Step3 />
          <Step4 />
        </Wizard>
      )}
    </div>
  );
}
